<template>
  <div class="container mescroll-touch">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
    <div class="containerBox" id="toTop">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox" @click="$router.push('/search')">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
      </div>
    </div>
    <div class="classify">
      <div class="classify_content" ref="wrapper">
        <div class="classify_wrapper">
          <span :class="{'active_classify': code == item.Value}" v-for="item in navBarList" :key="item.Value" @click="changeNavBar(item.TCode, item.Value, item.Name)">{{item.Name}}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="department_classify">
        <span :class="{'department_classify_active': SubjectTCode == AllTCode}" @click="selectOperative(AllTCode, '', '全部')">全部</span>
        <span :class="{'department_classify_active': SubjectTCode == item.TCcode}" v-for="(item, index) in operativeList" :key="index" @click="selectOperative(item.TCcode, item.code, item.Name)">{{item.Name}}</span>
      </div>
      <div class="banner" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="section">
        <div class="section_box" v-for="(item, index) in columnList" :key="index">
          <div class="section_item" @click="selectColumns(item.TCode, item.Code, item.DisplayName)">
            <img :src="ColumnTCode == item.TCode ? require('@/assets/images/'+columns[item.Code.split('_')[1]].activeIcon) : require('@/assets/images/'+columns[item.Code.split('_')[1]].icon)" />
            <span :class="{'section_item_active': ColumnTCode == item.TCode}">{{item.DisplayName}}</span>
          </div>
        </div>
      </div>
      <div id="dataList">
        <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
          <template>
            <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
                <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content">
                <span class="item_title">{{item.Title}}</span>
                <p class="item_time">{{item.yyyymmdd}}</p>
                <div class="item_label">
                  <span v-for="(label, labelIndex) in item.TagListBy" :key="labelIndex">{{label.DisplayName}}</span>
                </div>
                <div class="item_nums">
                  <div class="nums">
                    <img src="@/assets/images/browse_comm_icon@2x.png" />
                    <span>{{item.WatchCount}}</span>
                  </div>
                  <div class="nums">
                    <img src="@/assets/images/like_comm_icon@2x.png" />
                    <span>{{item.LikeCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
    </div>
    </div>
    </mescroll-vue>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import mixin from '../../common/mixin/mixins'
import BScroll from 'better-scroll'
import columns from '../../common/config/column'
import MescrollVue from 'mescroll.js/mescroll.vue'
import Sidebar from '../../components/sidebar.vue'

export default {
  name: 'SkillTraining',
  data() {
    return {
      sidebarShow: false,
      navBarList: [],
      code: '',
      operativeList: [],
      AllTCode: '',
      SubjectTCode: '',
      bannerList: [],
      columns: columns.jnpxColumns,
      columnList: [],
      ColumnTCode: '',
      col: 2,
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			}
    }
  },
  mixins: [mixin],
  components: {
    MescrollVue,
    Sidebar
  },
  computed: {
    ...mapState(['xwTipsShow']),
    KSCode() {
      return this.$route.query.Code;
    },
    CTCode() {
      return this.$route.query.ColumnTCode;
    },
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  watch: {
    SubjectTCode() {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    ColumnTCode() {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
  },
  created() {
    this.getDepartment();
    this.getColumnList();
    this.$store.commit('changeNavbarIndex', 1);
  },
  mounted() {
    // this.initBScroll();
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
    this.$store.commit('changeNavbarIndex', 1);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  methods: {
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=jnpx').then(res => {
        this.bannerList = res.Data;
      })
    },
    getDepartment() {
      this.$axios.get('/Api/api/Web/Subject/GetKSCode?Code=jnpx').then(res => {
        this.navBarList = res.Data;
        let result = this.navBarList.find(item => this.KSCode == item.Value );
        if(result) {
          this.SubjectTCode = result.TCode;
          this.AllTCode = result.TCode;
          this.code = result.Value;
        } else {
          this.SubjectTCode = res.Data[0].TCode;
          this.AllTCode = res.Data[0].TCode;
          this.code = res.Data[0].Value;
        }
        this.getOperative();
        this.getBanner();
        // this.getColumnList();
        this.initBScroll();
      })
    },
    // 初始化BScroll
    initBScroll() {
      this.$nextTick(() => {
        this.scroll = new BScroll(this.$refs['wrapper'], {
          startX: 0,
          click: true,
          scrollX: true,
          scrollY: false,
          eventPassthrough: 'vertical'
        })
      })
    },
    mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;

      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetALst', {
          SubjectTCode: this.SubjectTCode,
          ColumnTCode: this.ColumnTCode,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then(res => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          this.$forceUpdate()
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        })
      }, 300);
    },
    popupClose(val) {
      this.sidebarShow = val;
    },
    changeNavBar(TCode, code, name) {
      this.code = code;
      this.SubjectTCode = TCode;
      this.AllTCode = TCode;
      this.getOperative();
      // this.getColumnList();
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
      this.BuriedPoint(1, name, TCode, `/skillTraining`);
    },
    getOperative() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=2&pcode=' + this.code).then(res => {
        this.operativeList = res.Data;
      })
    },
    // 选择亚术式
    selectOperative(TCcode, code, name) {
      if(TCcode) {
        this.SubjectTCode = TCcode;
      } else {
        let index = this.navBarList.findIndex(e => e.Value == this.code);
        if(index != -1) {
          this.SubjectTCode = this.navBarList[index].TCode;
        }
      }
      this.BuriedPoint(1, name, TCcode, `/skillTraining`);
    },
    // 获取栏目
    getColumnList() {
      this.$axios.get('/Api/api/Web/Subject/GetColumnListByCode?Code=jnpx').then(res => {
        this.columnList = res.Data;
        if (this.columnList.length > 0) {
          if(this.CTCode) {
            this.ColumnTCode = this.CTCode;
          } else {
            this.ColumnTCode = this.columnList[0].TCode;
          }
        }
      })
    },
    // 选择栏目
    selectColumns(TCode, code, name) {
      this.ColumnTCode = TCode;
      this.BuriedPoint(1, name, TCode, `/skillTraining`);
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    imgclick(UrlLink) {
			let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 16px 0 56px;
  box-sizing: border-box;
  background-color: #F9F9F9;
  .containerBox {
    background-image: url('../../assets/images/bg_comm_png@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 16px;
  }
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 60px;
    height: auto;
  }
  .top {
    margin-bottom: 10px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .classify {
    display: flex;
    color: #FFFFFF;
    height: 31px;
    padding: 0 12px;
    .classify_content {
      display: flex;
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      .classify_wrapper {
        white-space: nowrap;
        span {
          position: relative;
          margin-right: 20px;
        }
        .active_classify {
          font-size: 18px;
          font-weight: bold;
        }
        .active_classify::after {
          content: "";
          width: 22px;
          height: 3px;
          border-radius: 3px;
          background-color: #FFFFFF;
          position: absolute;
          top: 25px;
          left: 50%;
          transform: translateX(-11px);
        }
      }
    }
    .more {
      font-size: 12px;
      margin-left: 10px;
      a {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        margin-top: 6px;
      }
      .addIcon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        opacity: 0.8;
      }
    }
  }
  .content {
    min-height: 100%;
    margin-top: 1px;
    padding: 10px 12px 0;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
  }
  .content:before{
    content: '';
    display: table;
  }
  .department_classify {
    display: flex;
    flex-wrap: wrap;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23%;
      height: 26px;
      border-radius: 26px;
      border: 1px solid #CCCCCC;
      font-size: 11px;
      color: #666666;
      box-sizing: border-box;
      margin-top: 10px;
    }
    span:not(:nth-child(4n)) {
      margin-right: calc(8% / 3);
    }
    .department_classify_active {
      color: #CA001B;
      border: 1px solid #CA001B;
    }
  }
  .banner {
    margin: 20px 0 15px 0;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
    overflow: hidden;
    .bannerImg {
      width: 100%;
      display: block;
    }
  }
  .section {
    display: flex;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px 0;
    margin-bottom: 14px;
    margin-top: 20px;
    .section_box {
      flex: 1;
      text-align: center;
    }
    .section_item {
      // flex: 1;
      // text-align: center;
      img {
        display: block;
        margin: 0 auto 5px;
        width: 50px;
        height: 50px;
      }
      span {
        font-size: 12px;
        color: #333333;
      }
      .section_item_active {
        color: #CA001B !important;
        font-weight: bold;
      }
    }
  }
  .list {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .item_img {
      position: relative;
      .item_cover {
        display: block;
        width: 100%;
      }
      .playIcon {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 30px;
        height: 30px;
      }
    }
    .item_content {
      padding: 5px 8px 8px;
      .item_title {
        height: 38px;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        word-wrap: break-word;
      }
      .item_time {
        font-size: 12px;
        color: #999999;
        line-height: 24px;
      }
      .item_label {
        display: flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          border-radius: 2px;
          padding: 0 6px;
          margin-right: 6px;
          margin-bottom: 5px;
          background-color: rgba(255, 73, 98, 0.15);
          font-size: 11px;
          color: #CA001B;
        }
      }
      .item_nums {
        display: flex;
        justify-content: flex-end;
        .nums {
          display: flex;
          align-items: center;
          img {
            width: 12px;
            margin: 0 2px 0 6px;
          }
          span {
            font-size: 11px;
            color: #666666;
          }
        }
      }
    }
  }
}
/deep/ .van-swipe__indicators {
  top: 90%;
}
</style>